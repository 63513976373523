import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { graphql } from 'gatsby';
import { Element } from 'react-scroll';

import BgScene from 'src/components/ui/BgScene';

import Layout from 'src/components/Layout';
import Sidenav from 'src/components/Sidenav';
import SectionHeader from 'src/components/SectionHeader';
import SectionAboutUs from 'src/components/SectionAboutUs';
import SectionServices from 'src/components/SectionServices';
import SectionCards from 'src/components/SectionCards';
import SectionContactUs from 'src/components/SectionContactUs';
import Footer from 'src/components/Footer';
import ContactUsForm from 'src/components/ContactUsForm';

import './layout.scss';

const IndexPage = () => {
  const servicesRef = useRef(null);
  const reviewsRef = useRef(null);
  const [servicesHeight, setServicesHeight] = useState(0);
  const [reviewHeight, SetReviewHeight] = useState(0);
  const [isTablet, setIsTablet] = useState(false);
  const [show, setShowState] = useState(false);

  const handleResize = useCallback(() => {
    setIsTablet(window.innerWidth <= 1000);
    if (servicesRef.current) setServicesHeight(servicesRef.current.clientHeight);
    if (reviewsRef.current) SetReviewHeight(reviewsRef.current.clientHeight);
  }, [servicesRef.current]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const showContactUs = () => {
    setShowState(!show);
  };

  return (
    <Layout className="layout" show={show}>
      <Sidenav onMailClick={showContactUs} />
      <Element name="home" id="home">
        <SectionHeader />
      </Element>
      <Element name="about" id="about">
        <SectionAboutUs />
      </Element>
      <BgScene
        triggerId="trigger-services"
        positionOffset={servicesHeight + 600}
        isActive={isTablet}
        beforeBg="rgb(40, 40, 40)"
        afterBg="rgb(248, 248, 248)"
      >
        <Element name="services" id="services">
          <SectionServices ref={servicesRef} />
        </Element>
        <Element name="work" id="work">
          <SectionCards isTablet={isTablet} />
        </Element>
      </BgScene>
      <BgScene
        triggerId="trigger-reviews"
        positionOffset={reviewHeight}
        isActive={isTablet}
        beforeBg="rgb(237, 237, 237)"
        afterBg="rgb(255, 217, 0)"
      >
        <Element name="contacts" id="contacts">
          <SectionContactUs isTablet={isTablet} showContactUs={showContactUs} />
        </Element>
        <Footer />
      </BgScene>
      <ContactUsForm show={show} onClickClose={showContactUs} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
