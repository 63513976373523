import React, {
  useState, useEffect, useCallback, memo,
} from 'react';
import cn from 'classnames';
import { Tween, PlayState } from 'react-gsap';

import Portal from 'src/components/ui/Portal';
import Icon from 'src/components/ui/Icon';
import Close from 'src/assets/images/svg_close.svg';
import * as styles from './Modal.module.scss';

const Modal = ({
  children,
  onClose,
  withVideo,
  className,
}) => {
  const [playState, setPlayState] = useState(PlayState.play);

  const close = useCallback(() => {
    document.documentElement.style.overflow = 'auto';
    setTimeout(() => setPlayState(PlayState.reverse), 200);
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
  }, []);

  return (
    <Portal>
      <Tween
        from={{
          opacity: 0,
          duration: 0.5,
          delay: 0.2,
          onReverseComplete: onClose,
        }}
        playState={playState}
      >
        <div className={cn(styles.modal, className)}>
          <button
            type="button"
            className={cn(styles.modal__close, withVideo && styles.modal__close_video)}
            onClick={close}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Icon src={Close} />
          </button>
          {children}
        </div>
      </Tween>
    </Portal>
  );
};

Modal.defaultProps = {
  withVideo: true,
  onClose: () => {},
};

export default memo(Modal);
