import React, {
  useEffect, useState, useRef, useMemo, memo,
} from 'react';
import { Scene, Controller } from 'react-scrollmagic';
import uniqueid from 'src/helpers/uniqueid';

const FadeScene = ({ tag, children, className }) => {
  const Tag = tag;
  const blockRef = useRef(null);
  const [isInTopScreenHalf, setIsInTopScreenHalf] = useState(false);
  const id = useMemo(() => `fadeScene-${uniqueid()}`, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleSetIsInTopScreenHalf = () => {
      if (blockRef.current) {
        const { top } = blockRef.current.getBoundingClientRect();
        setIsInTopScreenHalf(document.documentElement.clientHeight / 2 > top);
      }
    };

    handleSetIsInTopScreenHalf();
    window.addEventListener('scroll', handleSetIsInTopScreenHalf);
    return () => {
      window.removeEventListener('scroll', handleSetIsInTopScreenHalf);
    };
  }, [blockRef.current]);

  return (
    <div ref={blockRef} id={id} className={className}>
      <Controller>
        <Scene
          triggerHook={isInTopScreenHalf ? 'onLeave' : 'onEnter'}
          offset={isInTopScreenHalf ? -100 : 0}
          duration={200}
          triggerElement={`#${id}`}
        >
          {(progress) => {
            const correctProgress = isInTopScreenHalf ? 1 - progress : progress;
            return (
              <Tag style={{ opacity: correctProgress < 0.4 ? 0.4 : correctProgress }}>
                {children}
              </Tag>
            );
          }}
        </Scene>
      </Controller>
    </div>
  );
};

FadeScene.defaultProps = {
  tag: 'div',
};

export default memo(FadeScene);
