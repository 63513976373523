// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--2d913";
export var footer__copir = "Footer-module--footer__copir--a52a9";
export var footer__copirSec = "Footer-module--footer__copirSec--ff5cd";
export var footer__dash = "Footer-module--footer__dash--6c71b";
export var footer__link = "Footer-module--footer__link--baf74";
export var footer__mail = "Footer-module--footer__mail--6168e";
export var footer__mailSec = "Footer-module--footer__mailSec--cdc33";
export var footer__mail_svg = "Footer-module--footer__mail_svg--9f020";
export var footer__section = "Footer-module--footer__section--6164e";
export var footer__socSec = "Footer-module--footer__socSec--6e2a4";
export var footer__svg = "Footer-module--footer__svg--d67cc";
export var footer__text = "Footer-module--footer__text--ab538";