import { useTranslation } from 'gatsby-plugin-react-i18next';
import nestro1 from 'src/assets/images/cards/nestro_petrol_1.jpg';
import nestro2 from 'src/assets/images/cards/nestro_petrol_2.jpg';
import hennessy from 'src/assets/images/cards/hennessy.jpg';
import Bizzability from 'src/assets/images/cards/Bizzability.jpg';
import aviator from 'src/assets/images/cards/aviator.jpg';

export default function useCards() {
  const { t } = useTranslation('work');

  return [
    {
      id: 'card1',
      type: 'news',
      props: {
        titleList: ['Nestro petrol'],
        subtitle: t('loyalty system'),
        fullTitle: t('Nestro Petrol loyalty system'),
        src: nestro1,
        contentList: [
          t("We developed software for managing the loyalty system. It allows managers to quickly create new loyalty programs based on the data provided by the analytics module. This module collects consumer behavioral information, helps to predict demand and accurately calculate the effectiveness of the offer. The service requires only internet access to operate, is easily integrated with the customer's production systems and scalable due to its modular architecture."),
          t('Today the system serves more than 86 gas stations and thousands of customers in Bosnia and Herzegovina.'),
        ],
      },
    },
    {
      id: 'card2',
      type: 'news',
      props: {
        titleList: ['Nestro petrol'],
        subtitle: t('competitors monitoring system'),
        fullTitle: t('Nestro Petrol competitors monitoring system'),
        src: nestro2,
        contentList: [
          t('Price Monitor is a solution for storing and analyzing prices established by the company itself and by its competitors.'),
          t("Company’s agents can enter their own and their competitors' data into the database, services or prices for example. A powerful analytics and forecasting system helps to control customer's demand and traffic on points of sale."),
          t("Price monitor is a comprehensive solution which simplifies integration with existing equipment on the operator's side. Therefore it reduces costs of integration and system support."),
        ],
      },
    },
    {
      id: 'card3',
      type: 'news',
      props: {
        titleList: ['Hennessy'],
        subtitle: t('mobile application for Moët'),
        fullTitle: t('Mobile application for Hennessy Moët'),
        src: hennessy,
        contentList: [
          t('We created an app with an integrated catalog of strong alcohol for Android tablets installed directly at the points of sale.'),
          t('The app helps its user to learn more about the brand’s history, features of drinking different types of beverages and their best gastronomic combinations.'),
          t('The user also can search for a particular drink based on its aromatic-tasting characteristics , strength and price.'),
        ],
      },
    },
    {
      id: 'card4',
      type: 'news',
      props: {
        titleList: ['Bizzability'],
        subtitle: t('Design for advertising campaign'),
        fullTitle: t('Bizzability. Design for advertising campaign'),
        src: Bizzability,
        contentList: [
          t('Bizzability is a popular American startup accumulating all discounts of your city in one app. We developed banners, video clips and internal website pages for its advertising campaign. The project was quite challenging because of the 7 hours time zone difference, but the result exceeded all the expectations.'),
        ],
      },
    },
    {
      id: 'card5',
      type: 'news',
      props: {
        titleList: ['AVIATOR'],
        subtitle: t('Mobile application'),
        fullTitle: t('Mobile application'),
        src: aviator,
        contentList: [
          t("The future has come! We've developed a new mobile app for smart doors that can be controlled remotely. We provided total information security, sustainability and high operational speed of the app. All you need for control is your mobile phone."),
        ],
      },
    },
  ];
}
