import React, { memo, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import cn from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Close from 'src/assets/images/svg_close.svg';
import Icon from '../ui/Icon';
import * as styles from './ContactUsForm.module.scss';

const ContactUsForm = ({ show, onClickClose }) => {
  const { t } = useTranslation('contacts');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isValidStates, setValidState] = useState({
    name: true,
    email: true,
    message: true,
  });

  const [isSent, setSent] = useState(false);

  const debounceInput = debounce((name, value) => {
    setValidState({
      ...isValidStates,
      [name]: !!value,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  }, 500);

  const onInput = (event, name) => {
    const { target: { value } } = event;
    if (value && name) {
      debounceInput(name, value);
    }
  };

  const renderInputField = (Tag, name, value, placeholder, isNotValid) => (
    <Tag
      type="text"
      name={name}
      className={`input request ${name} ${isNotValid ? styles.error : ''}`}
      placeholder={placeholder}
      required
      onInput={(event) => onInput(event, name)}
    />
  );

  const validationForm = () => {
    const [mailElement] = Array.from(document.forms.ContactUsForm.getElementsByClassName('email'));
    let isValidEmail = false;

    if (mailElement) {
      const pattern = /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
      isValidEmail = pattern.test(mailElement.value);
    }

    const test = {
      name: !!formData.name,
      email: !!formData.email && isValidEmail,
      message: !!formData.message,
    };

    setValidState(test);

    const errors = Object.keys(test).filter((key) => !test[key]);
    return errors.length <= 0;
  };

  const onHandleSubmit = async (event) => {
    event.preventDefault();
    const result = validationForm();
    if (result) {
      fetch('/api/email', { method: 'POST', body: JSON.stringify(formData) }).then(() => {
        setSent(true);
      }).catch(() => {
        alert(t('Sorry, something went wrong :( Please, try again later.'));
      });
    }
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
  }, [show]);

  return (
    <div className={cn(styles.contactUsForm, show && styles.contactUsForm_show)}>
      <button
        type="button"
        className={cn(styles.contactUsForm__close)}
        onClick={onClickClose}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Icon src={Close} />
      </button>
      <div className={styles.contactUsForm__yellowLine} />
      <div className={styles.contactUsForm__formToSend}>
        <div className={styles.contactUsForm__content}>
          <h2 className={styles.contactUsForm__title}>{t('contact us')}</h2>
          <form id="ContactUsForm" onSubmit={onHandleSubmit} style={{ display: isSent && 'none' }}>
            <div>
              <div className={cn(styles.contactUsForm__content__input)}>
                {renderInputField('input', 'name', formData.name, t('Your name', { ns: 'common' }), !isValidStates.name)}
              </div>
              <div className={(styles.contactUsForm__content__input)}>
                {renderInputField('input', 'email', formData.email, t('E-mail', { ns: 'common' }), !isValidStates.email)}
              </div>
              <div className={cn(styles.contactUsForm__content__input)}>
                {renderInputField('textarea', 'message', formData.message, t('Your message', { ns: 'common' }), !isValidStates.message)}
              </div>
            </div>
            <button
              type="submit"
              className={styles.contactUsForm__content__sendBtn}
            >
              {t('send')}
            </button>
          </form>
          <div
            className={cn(
              styles.contactUsForm__content__success,
              isSent && styles.contactUsForm__content__success_visible,
            )}
          >
            <div className={styles.contactUsForm__content__success_text}>
              <p>{t('Thank you for contacting us.')}</p>
              <p>{t('We will respond to your request as soon as possible.')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactUsForm);
