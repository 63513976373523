import React, { memo } from 'react';
import Icon from 'src/components/ui/Icon';
import cn from 'classnames';

import arrow from 'src/assets/images/svg_arrow.svg';
import plus from 'src/assets/images/svg_close.svg';
import * as styles from './Button.module.scss';

const icons = { arrow, plus };

const Button = ({
  type, // arrow, plus, bordered
  iconDirection, // left, right
  color, // light, dark
  active,
  onClick,
  children,
  className,
  disabled,
  tag,
}) => {
  const Tag = tag;
  return (
    <Tag
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn(
        styles.button,
        disabled && styles.button_disabled,
        styles[`button_${color}`],
        styles[`button_${type}`],
        styles[`button_${iconDirection}`],
        { [styles.button_active]: active },
        className,
      )}
    >
      {icons[type] && <Icon src={icons[type]} className={styles.button__icon} />}
      <span className={styles.button__text}>{children}</span>
    </Tag>
  );
};

Button.defaultProps = {
  type: 'bordered',
  iconDirection: 'left',
  color: 'black',
  active: false,
  onClick: () => {},
  disabled: false,
  tag: 'button',
};

export default memo(Button);
