import React, {
  useRef, useEffect, useState, useCallback, memo,
} from 'react';
import { Scene, Controller } from 'react-scrollmagic';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import FadeScene from 'src/components/ui/FadeScene';
import TextScene from './TextScene';
import * as styles from './SectionAboutUs.module.scss';

const SectionAboutUs = () => {
  const { t } = useTranslation('about');
  const [sectionHeight, setSectionHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const sectionRef = useRef(null);

  const handleResize = useCallback(() => {
    setWindowHeight(document.documentElement.clientHeight);
    if (sectionRef.current) {
      const { scrollHeight, offsetHeight } = sectionRef.current;
      setSectionHeight(Math.max(scrollHeight, offsetHeight));
    }
  }, [setSectionHeight, sectionRef]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [sectionRef, setSectionHeight]);

  return (
    <Controller>
      <Scene triggerHook="onEnter" duration={sectionHeight} offset={0}>
        {(progress) => {
          const pxProgress = progress * sectionHeight;

          return (
            <section className={styles.about} style={{ height: sectionHeight }}>
              <div
                className={styles.about__item}
                ref={sectionRef}
                style={{ position: progress === 1 ? 'fixed' : 'relative' }}
              >
                <FadeScene
                  className={styles.about__content}
                  tag="p"
                  scrollPosition={pxProgress}
                  windowHeight={windowHeight}
                  sectionHeight={sectionHeight}
                >
                  {t('Accent is a')}
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('Singapore-based IT company')}</TextScene>
                  {' '}
                  {t('with expertise in design, web and mobile development.')}
                </FadeScene>

                <FadeScene
                  className={styles.about__content}
                  tag="p"
                  scrollPosition={pxProgress}
                  windowHeight={windowHeight}
                  sectionHeight={sectionHeight}
                >
                  {t('We help businesses from')}
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('fintech')}</TextScene>
                  ,
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('retail')}</TextScene>
                  ,
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('media')}</TextScene>
                  ,
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('telecom')}</TextScene>
                  <TextScene scrollPosition={pxProgress}>
                    {' '}
                    {t('and')}
                    {' '}
                  </TextScene>
                  <TextScene scrollPosition={pxProgress}>{t('enterprise')}</TextScene>
                  {' '}
                  {t('create new software projects')}
                  {t('and support the already existing.')}
                </FadeScene>

                <FadeScene
                  className={styles.about__content}
                  tag="p"
                  scrollPosition={pxProgress}
                  windowHeight={windowHeight}
                  sectionHeight={sectionHeight}
                >
                  {t('When out-of-the-box solutions fail,')}
                  {' '}
                  <TextScene scrollPosition={pxProgress}>{t('we step in.')}</TextScene>
                </FadeScene>
              </div>
            </section>
          );
        }}
      </Scene>
    </Controller>
  );
};

export default memo(SectionAboutUs);
