// extracted by mini-css-extract-plugin
export var preview = "Preview-module--preview--598fe";
export var preview__close = "Preview-module--preview__close--280dd";
export var preview__content = "Preview-module--preview__content--02421";
export var preview__fullTitle = "Preview-module--preview__fullTitle--fe5c8";
export var preview__img = "Preview-module--preview__img--2b17d";
export var preview__info = "Preview-module--preview__info--4ccc4";
export var preview__info_hide = "Preview-module--preview__info_hide--135fa";
export var preview__scrollContainer = "Preview-module--preview__scrollContainer--b981c";
export var preview__subTitle = "Preview-module--preview__subTitle--2e87a";
export var preview__text = "Preview-module--preview__text--e4148";
export var preview__textList = "Preview-module--preview__textList--6fa07";
export var preview__title = "Preview-module--preview__title--f50ba";
export var preview__title__secondTitle = "Preview-module--preview__title__secondTitle--cbc49";
export var preview__titles = "Preview-module--preview__titles--055e2";
export var preview__titles_hide = "Preview-module--preview__titles_hide--1804d";
export var preview__videoIcon = "Preview-module--preview__videoIcon--e06d6";
export var preview__wrapper = "Preview-module--preview__wrapper--80fc5";