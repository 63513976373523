import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TweenMax, TimelineLite } from 'gsap/all';
import cn from 'classnames';

import ScrollBar from 'src/components/ui/ScrollBar';

import Button from 'src/components/ui/Button';
import TextSelection from 'src/components/ui/TextSelection';
import Icon from 'src/components/ui/Icon';

import Close from 'src/assets/images/svg_close.svg';
import VideoPlay from 'src/assets/images/svg_video_play.svg';

import * as styles from './Preview.module.scss';

const timeline = new TimelineLite();

const Preview = ({
  isVideo,
  onlyEvent,
  onInteract,
  titleList,
  fullTitle,
  subtitle,
  contentList,
  src,
  className,
}) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const imgRef = useRef(null);
  const contentRef = useRef(null);
  const fullTitleRef = useRef(null);
  const textRef = useRef(null);
  const closeRef = useRef(null);

  const handleOpen = useCallback(() => {
    if (
      !isDisable
      && !isOpen
      && imgRef.current
      && contentRef.current
      && closeRef.current
      && fullTitleRef.current
      && textRef.current
    ) {
      setIsOpen(true);
      setIsDisable(true);
      TweenMax.to(imgRef.current, { duration: 0.3, x: '-50%' });
      timeline
        .fromTo(contentRef.current, { x: '100%' }, { duration: 0.3, x: 0 })
        .to(contentRef.current, { duration: 0.3, delay: 0.3, width: '60%' })
        .fromTo(closeRef.current, { opacity: 0 }, { opacity: 1, duration: 0.2 })
        .fromTo(fullTitleRef.current, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.2 })
        .fromTo(textRef.current, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.2 })
        .call(() => setIsDisable(false));
    }
  }, [imgRef, contentRef, closeRef, fullTitleRef, textRef, setIsDisable, setIsOpen, isDisable]);

  const handleClose = useCallback(() => {
    if (
      !isDisable
      && isOpen
      && imgRef.current
      && contentRef.current
      && closeRef.current
      && fullTitleRef.current
      && textRef.current
    ) {
      setIsDisable(true);
      TweenMax.to(closeRef.current, { opacity: 0, duration: 0.2 });
      timeline
        .to(textRef.current, { y: 20, opacity: 0, duration: 0.2 })
        .to(fullTitleRef.current, { y: 20, opacity: 0, duration: 0.2 })
        .to(contentRef.current, { duration: 0.3, width: '60%' })
        .then(() => {
          TweenMax.to(imgRef.current, { duration: 0.3, x: 0 });
          TweenMax.to(contentRef.current, { duration: 0.3, x: '100%' });
          TweenMax.delayedCall(0.6, () => {
            setIsOpen(false);
            setIsDisable(false);
          });
        });
    }
  }, [imgRef, contentRef, closeRef, fullTitleRef, textRef, setIsDisable, setIsOpen, isDisable]);

  const onEnterDown = useCallback(
    (e) => {
      if ((e.code === 'Enter' || e.key === 'Enter') && !isDisable) {
        if (isOpen) handleClose();
        else handleOpen();
      }
    },
    [handleOpen, handleClose, isDisable, isOpen],
  );

  return (
    <button
      type="button"
      className={cn(className, styles.preview)}
      onMouseEnter={() => setIsHover(true)}
      onMouseDown={(e) => e.preventDefault()}
      onFocus={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onBlur={() => setIsHover(false)}
      onKeyDown={onlyEvent ? onInteract : onEnterDown}
      onClick={onlyEvent ? onInteract : handleOpen}
    >
      <div className={cn(styles.preview__titles, isOpen && styles.preview__titles_hide)}>

        {isVideo && (
        <div className={styles.preview__videoIcon}>
          <Icon src={VideoPlay} />
        </div>
        )}
        {titleList.map((item, index) => (
          <TextSelection
            className={cn(styles.preview__title, {
              [styles.preview__title__secondTitle]: index === 1,
            })}
            tag="h2"
            active={isHover}
            key={item}
          >
            {item}
          </TextSelection>
        ))}
        <span className={styles.preview__subTitle}>{subtitle}</span>

      </div>
      <div className={styles.preview__wrapper}>
        <div
          className={styles.preview__img}
          style={{ backgroundImage: `url(${src})` }}
          ref={imgRef}
        >
          <Button
            className={cn(styles.preview__info, isOpen && styles.preview__info_hide)}
            type="arrow"
            iconDirection="right"
            color="light"
            active={isHover}
            tag="div"
          >
            {t('info')}
          </Button>
        </div>
        {!onlyEvent && (
          <div className={styles.preview__content} ref={contentRef}>
            <div className={styles.preview__close} ref={closeRef} onClick={handleClose}>
              <Icon src={Close} />
            </div>
            <div className={styles.preview__text}>
              <h3 className={styles.preview__fullTitle} ref={fullTitleRef}>
                {fullTitle}
              </h3>
              <div ref={textRef} className={styles.preview__scrollContainer}>
                <ScrollBar
                  className={styles.preview__textList}
                  style={{ position: 'relative' }}
                  height="calc(100%)"
                >
                  {contentList.map((item) => (
                    <p key={item}>{item}</p>
                  ))}
                </ScrollBar>
              </div>
            </div>
          </div>
        )}
      </div>
    </button>
  );
};

Preview.defaultProps = {
  onlyEvent: false,
  isVideo: false,
  subtitle: '',
  fullTitle: '',
  titleList: [],
  contentList: [],
  onInteract: () => {},
};

export default memo(Preview);
