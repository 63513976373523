import cn from 'classnames';
import React, { memo } from 'react';

/* import Fb from 'src/assets/images/svg_fb.svg';
import Tw from 'src/assets/images/svg_tw.svg';
import Insta from 'src/assets/images/svg_insta.svg';
import Mail from 'src/assets/images/svg_mail.svg';
import Icon from 'src/components/ui/Icon'; */
import * as styles from './Footer.module.scss';

// import facebook from '../../assets/images/facebook.png';
// import twitter from '../../assets/images/twitter.png';
// import linked from '../../assets/images/linkedin.png';
/* const hrefs = [
  {
    id: '0',
    href: 'https://www.facebook.com/accentdev/',
    src: Fb,
  },
  {
    id: '1',
    href: 'https://twitter.com/accent_soft',
    src: Tw,
  },
  {
    id: '2',
    href: 'https://www.linkedin.com/company/accent-soft',
    src: Insta,
  },
]; */

const Footer = () => (
  <div className={styles.footer}>
    <div className={cn(styles.footer__section, styles.footer__copirSec)}>
      <span className={styles.footer__copir}>
        © 2019-
        {new Date().getFullYear()}
        , Accent
      </span>
    </div>
    {/* <div className={cn(styles.footer__section, styles.footer__socSec)}>
      <div className={styles.footer__dash} />
      <span className={styles.footer__text}>Follow us</span>
      <span>
        { hrefs.map((item) => (
          <a key={item.id} className={styles.footer__link} href={item.href}>
            <Icon className={styles.footer__svg} src={item.src} />
          </a>
        ))}
      </span>
    </div> */}
    <div className={cn(styles.footer__section, styles.footer__mailSec)}>
      <p href="www" className={cn(styles.footer__mail, styles.footer__mailCont)}>
        {/* <Icon className={cn(styles.footer__icon, styles.footer__mail_svg)} src={Mail} /> */}
        <a href="mailto:contact@accentdev.com">contact@accentdev.com</a>
      </p>
    </div>
  </div>
);

export default memo(Footer);
