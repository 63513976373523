// extracted by mini-css-extract-plugin
export var sidenav = "Sidenav-module--sidenav--68508";
export var sidenav__link = "Sidenav-module--sidenav__link--213c4";
export var sidenav__logo = "Sidenav-module--sidenav__logo--f56f7";
export var sidenav__logoImg = "Sidenav-module--sidenav__logoImg--44c49";
export var sidenav__mail = "Sidenav-module--sidenav__mail--c3fa3";
export var sidenav__mailImg = "Sidenav-module--sidenav__mailImg--436d8";
export var sidenav__menu = "Sidenav-module--sidenav__menu--aa2f1";
export var sidenav__menuItem = "Sidenav-module--sidenav__menuItem--cb76a";
export var sidenav__menuItem_active = "Sidenav-module--sidenav__menuItem_active--ca909";
export var sidenav__menuList = "Sidenav-module--sidenav__menuList--8c818";
export var sidenav__text = "Sidenav-module--sidenav__text--d7ede";
export var sidenav_open = "Sidenav-module--sidenav_open--4be3e";