import React, { forwardRef, memo } from 'react';
import cn from 'classnames';

import * as styles from 'src/components/ui/TextSelection/TextSelection.module.scss';

const TextSelection = forwardRef(({
  theme, // yellow
  className,
  children,
  active,
  tag,
  withHover,
}, ref) => {
  const Tag = tag;
  return (
    <Tag
      className={cn(
        styles.selection,
        styles[`selection_${theme}`],
        {
          [styles.selection_active]: active,
          [styles.selection_hover]: withHover,
        },
        className,
      )}
      ref={ref}
    >
      {children}
    </Tag>
  );
});

TextSelection.defaultProps = {
  theme: 'yellow',
  active: false,
  tag: 'span',
  withHover: false,
};

export default memo(TextSelection);
