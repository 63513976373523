import React, { forwardRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import * as styles from './ScrollBar.module.scss';

const ScrollBar = ({
  className,
  children,
  height,
}, ref) => (
  <div className={className}>
    <Scrollbar
      ref={ref}
      style={{ position: 'relative', height: height || 100 }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className={styles.trackVertical} />;
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className={styles.thumbVertical} />;
        },
      }}
    >
      {children}
    </Scrollbar>
  </div>
);

export default forwardRef(ScrollBar);
