// extracted by mini-css-extract-plugin
export var active = "SectionContactUs-module--active--37bca";
export var contact = "SectionContactUs-module--contact--6d829";
export var contact__container = "SectionContactUs-module--contact__container--1ce1c";
export var contact__image_container = "SectionContactUs-module--contact__image_container--9820e";
export var contact__image_container_active = "SectionContactUs-module--contact__image_container_active--9cedb";
export var contact__point = "SectionContactUs-module--contact__point--ddfc9";
export var contact__slider = "SectionContactUs-module--contact__slider--8726e";
export var contact__sliderRus = "SectionContactUs-module--contact__slider-rus--03e68";
export var contact__sliderSin = "SectionContactUs-module--contact__slider-sin--675f3";
export var contact__sliderUsa = "SectionContactUs-module--contact__slider-usa--2f621";
export var contact__slider__dash = "SectionContactUs-module--contact__slider__dash--47a9d";
export var contact__slider_btn = "SectionContactUs-module--contact__slider_btn--a42f8";
export var contact__slider_info = "SectionContactUs-module--contact__slider_info--c3147";
export var contact__slider_item = "SectionContactUs-module--contact__slider_item--34c1c";
export var contact__slider_items = "SectionContactUs-module--contact__slider_items--02126";
export var contact__slider_mobile = "SectionContactUs-module--contact__slider_mobile--990ca";
export var contact__slider_mobile_btn = "SectionContactUs-module--contact__slider_mobile_btn--634d1";
export var contact__slider_mobile_btn_active = "SectionContactUs-module--contact__slider_mobile_btn_active--8e713";
export var contact__slider_nav = "SectionContactUs-module--contact__slider_nav--bd6d0";
export var contact__slider_nav_item = "SectionContactUs-module--contact__slider_nav_item--160dd";
export var contact__slider_nav_item_active = "SectionContactUs-module--contact__slider_nav_item_active--eedfe";
export var contact__slider_nav_wrapper = "SectionContactUs-module--contact__slider_nav_wrapper--4312e";
export var contact__slider_text = "SectionContactUs-module--contact__slider_text--f096f";
export var contact__slider_text_wrapper = "SectionContactUs-module--contact__slider_text_wrapper--c69d6";
export var contact__title = "SectionContactUs-module--contact__title--a6ba2";