import React, { memo } from 'react';
import YouTube from 'react-youtube';
import * as styles from './VideoPlayer.module.scss';

const YTOptions = {
  width: '100%',
  height: '100%',
  playerVars: {
    cc_load_policy: 1,
    controls: 0,
    enablejsapi: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    autohide: 0,
    iv_load_policy: 3,
    autoplay: 0,
  },
};

const VideoPlayer = ({ id }) => (
  <div className={styles.videoPlayer}>
    <YouTube
      containerClassName={styles.videoPlayer__container}
      videoId={id}
      opts={YTOptions}
    />
  </div>
);

export default memo(VideoPlayer);
