// extracted by mini-css-extract-plugin
export var services = "SectionServices-module--services--13492";
export var services__H2 = "SectionServices-module--services__H2--f3cee";
export var services__H7 = "SectionServices-module--services__H7--74a74";
export var services__img = "SectionServices-module--services__img--0e838";
export var services__info = "SectionServices-module--services__info--89d71";
export var services__infoServ = "SectionServices-module--services__infoServ--da7ad";
export var services__left = "SectionServices-module--services__left--f1ee0";
export var services__list = "SectionServices-module--services__list--df8d6";
export var services__listItem = "SectionServices-module--services__listItem--fa593";
export var services__macImg = "SectionServices-module--services__macImg--c3431";
export var services__mobImg = "SectionServices-module--services__mobImg--17352";
export var services__planshImg = "SectionServices-module--services__planshImg--9e7fc";
export var services__points = "SectionServices-module--services__points--a0285";
export var services__right = "SectionServices-module--services__right--0b4d7";
export var services__rightText = "SectionServices-module--services__rightText--ada12";
export var services__text = "SectionServices-module--services__text--98ed4";
export var services__textContent = "SectionServices-module--services__textContent--5d651";
export var services__textContent_left = "SectionServices-module--services__textContent_left--e2c05";
export var services__textContent_right = "SectionServices-module--services__textContent_right--ebdf8";
export var services__titleH1 = "SectionServices-module--services__titleH1--190e6";
export var services__titleH7 = "SectionServices-module--services__titleH7--8cb76";