import React, { memo } from 'react';
import TextSelection from 'src/components/ui/TextSelection';
import { Scene, Controller } from 'react-scrollmagic';

import * as styles from './SectionAboutUs.module.scss';

const TextScene = ({ children }) => (
  <Controller>
    <Scene triggerHook="onEnter" offset={200}>
      {(pr) => (
        <TextSelection className={styles.about__text} active={pr > 0}>
          {children}
        </TextSelection>
      )}
    </Scene>
  </Controller>
);

export default memo(TextScene);
