import React, { memo } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import * as styles from './BgScene.module.scss';

const BgScene = ({
  children, positionOffset, duration, beforeBg, afterBg, isActive, triggerId,
}) => (isActive ? (
  <div className={styles.bgScene} style={{ backgroundColor: beforeBg }}>
    {children}
  </div>
) : (
  <div id={triggerId} className={styles.bgScene}>
    <Controller>
      <Scene
        triggerHook="onEnter"
        duration={duration}
        triggerElement={`#${triggerId}`}
        offset={positionOffset}
      >
        {(progress) => (
          <Tween
            position={190}
            to={{
              backgroundColor: afterBg,
            }}
            totalProgress={progress}
            paused
          >
            <div style={{ backgroundColor: beforeBg }}>{children}</div>
          </Tween>
        )}
      </Scene>
    </Controller>
  </div>
));

BgScene.defaultProps = {
  isTablet: false,
  duration: 300,
  positionOffset: 0,
  beforeBg: 'rgb(40, 40, 40)',
  afterBg: 'rgb(248, 248, 248)',
  triggerId: 'triggerId',
};

export default memo(BgScene);
