// extracted by mini-css-extract-plugin
export var header = "SectionHeader-module--header--4d1a7";
export var header__bgBase = "SectionHeader-module--header__bgBase--1d141";
export var header__container = "SectionHeader-module--header__container--b7b2f";
export var header__dash = "SectionHeader-module--header__dash--31206";
export var header__subTitle = "SectionHeader-module--header__subTitle--a5701";
export var header__subTitleText = "SectionHeader-module--header__subTitleText--be5d9";
export var header__title = "SectionHeader-module--header__title--a6d2c";
export var header__titleH1 = "SectionHeader-module--header__titleH1--7f74c";
export var header__treangle = "SectionHeader-module--header__treangle--cab7b";
export var header__treangles = "SectionHeader-module--header__treangles--34dfc";
export var header__yellowLine = "SectionHeader-module--header__yellowLine--cd908";