// extracted by mini-css-extract-plugin
export var button = "Button-module--button--c273b";
export var button__icon = "Button-module--button__icon--d76cb";
export var button__text = "Button-module--button__text--a5c20";
export var button_active = "Button-module--button_active--f7e48";
export var button_arrow = "Button-module--button_arrow--9d866";
export var button_bordered = "Button-module--button_bordered--62314";
export var button_disabled = "Button-module--button_disabled--6b972";
export var button_left = "Button-module--button_left--e3579";
export var button_light = "Button-module--button_light--80c84";
export var button_plus = "Button-module--button_plus--b06aa";
export var button_right = "Button-module--button_right--6edc0";